<div class="product-card d-flex flex-column">
	<a routerLink="{{productNavigateUrl}}" class="product-image-container">
		<img src="{{productImageUrl}}" class="product-card-image p-2" alt>
	</a>
	<div class="p-3 d-flex justify-content-between flex-column flex-grow-1">
		<div class="pb-2">
			<div class="d-flex flex-column p-0">
				<a class="cstm-anchor-primary" routerLink="{{productNavigateUrl}}">
					{{productId}}
				</a>
				<a *ngIf="productName?.length > 0" class="cstm-anchor-primary" routerLink="{{productNavigateUrl}}">
					{{productName?.length > 0 ? productName : "No Description"}}
				</a>
			</div>
		</div>
		<div>
			<div *ngIf="userLoggedIn">
				<div *ngIf="obsoleteFlag else checkQuotation" [ngClass]="{'obsolete-label': checkAccess(),}">Obsolete</div>
				<ng-template #checkQuotation>
					<div *ngIf="quotationFlag else displayPrice">
						{{"psp.priceNotAvailable" |cxTranslate}}
					</div>
				</ng-template>
				<ng-template #displayPrice>
					<span>
						<b>
							{{ (currency | currencyFormat) + " " }}
						</b>
						{{ getPrice() | currencyPrice }}
					</span>
				</ng-template>
			</div>
			<div *ngIf="userLoggedIn && !obsoleteFlag && checkAccess()">
				<div class="row pt-3 m-0 quantity-selector d-flex flex-wrap flex-md-nowrap flex-lg-wrap flex-xl-nowrap">
					<div class="col-5 p-0 inputFlex flex-lg-grow-1 flex-xl-grow-0">
						<input #value
							[title]="isMaxLmtReached ? 'The maximum quantity of this Product allowed has already been added to the Cart':''"
							[(ngModel)]="pdqty" [disabled]="maximumLimit()" (input)="checkQty(value.value)"
							(blur)="updateQty(value.value)" type="number" class="form-control m-0 Cartnum" title
							step="{{qtyStep}}" min="{{minimumQty}}" max="{{maximumQty}}" value="{{pdqty}}">
					</div>
					<button
						[title]="isMaxLmtReached ? 'The maximum quantity of this Product allowed has already been added to the Cart' : ''"
						[disabled]="maximumLimit()" (click)="addToCart(value.value, productId)" type="button"
						class="col-7 p-0 btn buttonLabel btn-outline-primary">
						<ng-container *ngIf="(isLoading$ | async) && productId == id; else defaultContent">
							<i class="fa fa-spinner fa-spin fa-lg"></i>
						</ng-container>
						<ng-template #defaultContent>
							<i *ngIf="apiSuccess && productId == id; else buttonText" class="fa fa-check fa-lg"></i>
							<ng-template #buttonText>
								<span *ngIf="isdataloading">
									{{ quotationFlag ?('psp.requestQuotation'|cxTranslate) :
									('psp.addToCart'|cxTranslate)}}
								</span>
							</ng-template>
						</ng-template>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>