import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductListService } from '../../../SGRE-custom/product-list-page/services/product-list.service';

@Component({
  selector: 'app-pagination-template',
  templateUrl: './pagination-template.component.html',
  styleUrl: './pagination-template.component.scss'
})
export class PaginationTemplateComponent {

  public pageNumber: any;
  @Input() first = 0;
  @Input() currentPage: number;
  @Input() rows: number;
  @Input() totalPages: number;
  @Output() pageChange = new EventEmitter<any>();

  constructor(private productsService: ProductListService) { }

  onPageChange(event) {
    this.pageNumber = event.page;
    let obj = {
      pageNumber: this.pageNumber,
      pageSize: this.rows,
      query: this.productsService.searchTextboxQuery
    }
    this.pageChange.emit(obj);
  }

}
