import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { BehaviorSubject, Observable, delay, map } from 'rxjs';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-card-template',
  templateUrl: './card-template.component.html',
  styleUrl: './card-template.component.scss',
})
export class CardTemplateComponent implements OnInit {
  @Input() productId: string;
  @Input() productName: string;
  @Input() productPrice: any;
  @Input() index: any;
  @Input() userLoggedIn: boolean = false;
  @Input() obsoleteFlag: boolean = false;
  @Input() quotationFlag: boolean = false;
  @Input() productDescription: string;
  @Input() productNavigateUrl: string;
  @Input() productImageUrl: string;
  @Input() productQuantity: number = 1;
  @Input() qtyStep: number = 1;
  @Input() minimumQty: number = 1;
  @Input() maximumQty: number = 1000;
  @Input() currency;
  @Input() apiCallSuccess: boolean = false;
  @Output() addToCartClk = new EventEmitter<any>();

  public symbol: string;
  public price: string;
  public userRoles: any[];
  public isMaxLmtReached = false;
  isLoading = false;
  isdataloading = true;
  productqtyflag: boolean = false;
  pdqty: any;
  id: string;
  isLoading$: Observable<any>;
  apiSuccess = false;
  public maxQtyLimit: number;

  constructor(
    private storageService: StorageService,
    private globalService: GlobalService,
    private changeRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.isLoading$ = this.globalService.loading$.pipe(
      map((data) => {
        if (this.globalService.id.value && this.apiCallSuccess === true) {
          this.apiSuccess = this.apiCallSuccess;
          this.id = this.globalService.id.value;
        }
        setTimeout(() => {
          this.id = "";
          this.apiSuccess = false;
          this.apiCallSuccess = false;
          this.changeRef.detectChanges();
        }, 2000);
        return data;
      })
    );
    this.maxQtyLimit = this.maximumQty;
    let availbleQty = this.getAvailableQty();
    this.pdqty = availbleQty > 0 ? this.qtyStep : this.minimumQty;
    this.minimumQty = availbleQty > 0 ? this.qtyStep : this.minimumQty;
    if (availbleQty) {
      this.maximumQty = this.maxQtyLimit - availbleQty;
      this.maximumLimit();
      this.isMaxLmtReached = availbleQty >= this.maxQtyLimit;
    }
  }

  maximumLimit() {
    let availbleQty = this.getAvailableQty();
    return availbleQty >= this.maxQtyLimit
  }

  checkAccess(): boolean {
    this.userRoles = this.storageService.userRoles;
    return this.userRoles?.includes('Requester');
  }
  checkRoleAccess(): boolean {
    this.userRoles = this.storageService.userRoles;
    return (
      this.userRoles?.includes('Requester') ||
      this.userRoles?.includes('Purchaser')
    );
  }

  getIndex() {
    const str = String(this.productPrice);
    const match = str?.match(/\d/);
    return match.index as number;
  }

  getPrice() {
    this.price = String(this.productPrice)?.substring(this.getIndex());
    return this.price;
  }

  checkMaximumLimit() {
    let cartObj = this.storageService.activeCart?.entries?.find(
      (item) => item.product.baseProduct === this.productId
    );
    let availbleQty = cartObj?.quantity ? cartObj.quantity : 0;
    if (availbleQty) {
      this.maximumQty = this.maxQtyLimit - availbleQty;
      this.maximumLimit();
    }
  }

  checkQty(val: number | string) {
    this.checkMaximumLimit();
  }

  addToCart(qty, productId) {
    this.apiSuccess = false;
    this.id = productId;
    this.globalService.id.next(productId);
    this.addToCartClk.emit({ index: this.index, quantity: qty });
  }

  getAvailableQty() {
    let cardDetails = this.storageService.activeCart;
    let cartObj = cardDetails?.entries?.find(
      (item) => item.product.baseProduct === this.productId
    );
    let quantity = cartObj?.quantity ? cartObj.quantity : 0;
    return quantity;
  }

  updateQty(qty) {
    if (+qty === 0 || qty < this.minimumQty) {
      qty = this.minimumQty;
      this.pdqty = qty;
    }
    let cartObj = this.storageService.activeCart?.entries?.find(
      (item) => item.product.baseProduct === this.productId
    );
    let availbleQty = cartObj?.quantity ? cartObj.quantity : 0;
    if (qty > this.maxQtyLimit - availbleQty) {
      this.pdqty = availbleQty ? this.maxQtyLimit - availbleQty : this.maximumQty;
    } else {
      this.pdqty = this.checkQtyRange(qty, this.qtyStep);
    }
  }

  checkQtyRange(qty, step): string {
    let updatedQty;
    if (qty > this.maximumQty) {
      updatedQty = this.maximumQty;
    } else if (qty <= step) {
      updatedQty = step;
    } else if (qty % step != 0) {
      updatedQty = Math.round(qty / step) * step;
    } else {
      updatedQty = qty;
    }
    return updatedQty;
  }
}
