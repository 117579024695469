import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductListService } from '../../services/product-list.service';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { Image, PlpInfo } from '../../models/plpInfo';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrl: './product-grid.component.scss'
})
export class ProductGridComponent implements OnInit, OnDestroy {

  protected productsList: Observable<PlpInfo>;
  protected productsListData: any[];
  public userLoggedIn: Observable<any>;
  public imageUrl: any = environment.siteUrls.getBackofficeUrl.slice(0, -1);
  private unsubscribe$ = new Subject<void>();
  public first = 0;
  public rows = 30;
  public totalRecords;
  public requestObject;
  public productId: string;
  apiCallSuccess: boolean = false;

  constructor(
    private productsService: ProductListService,
    private globalService: GlobalService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.userLoggedIn = this.globalService.loginBanner$;
    this.getProductsList();
    this.globalService.recreate(false);
    this.handleSubscriptions();
  }

  getProductsList() {
    this.requestObject = { currentPage: 0, pageSize: 30, fields: 'FULL' };
    let queryVal: string;
    if (this.storageService.query) {
      queryVal = this.storageService.query;
      // this.storageService.query = '';
      localStorage.removeItem(AppConstants.SessionStorageKeys.query);
      this.requestObject['query'] = queryVal;
    }
    // Reset Filters & Search
    this.productsService.currentPage = 0;
    this.productsService.rows = 30;
    this.productsService.getProductsList(this.requestObject);
    this.productsList = this.productsService.productsList$.pipe(
      tap(data => {
        this.productsListData = data?.products;
        this.totalRecords = data?.pagination?.totalResults
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  handleSubscriptions() {
    // on Mini-cart GET/UPDATE actions
    this.globalService.productSearch$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) { this.getProductsList(); }
      });

    // on LE header-dropdown/PSP/PLP-filter change actions
    this.productsService.pagination$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data) { this.first = 0; }
      });
  }

  getProductUrl(urlVal: string): string {
    return this.globalService.getProductUrl(urlVal);
  }

  getImageUrl(imgArr: Image[]) {
    return imgArr?.length > 0 && imgArr?.at(0)?.url
      ? environment.siteUrls.getBackofficeUrl + imgArr.at(0).url
      : '../../../../assets/images_and_icons/image1.jpg';
  }

  getUpdatedMinQty(minQuantity, interval) {
    return this.globalService.getUpdatedMinQty(minQuantity, interval);
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    this.productsService.currentPage = event.page;
    this.productsService.rows = event.rows;
    this.requestObject = {
      currentPage: event.page,
      pageSize: this.rows,
      query: ''
    };
    if (this.productsService.searchQuery) {
      this.requestObject.query += this.productsService.searchQuery;
    }
    if (this.productsService.filterQuery) {
      this.requestObject.query += this.productsService.filterQuery;
    }
    this.productsService.getProductsList(this.requestObject);
  }

  addToCart(event) {
    let index = event.index;
    let paramsObject = {
      code: this.productsListData[index]?.code,
      quantity: event.quantity,
    };
    let requestObject = {
      product: { code: this.productsListData[index]?.code, },
      quantity: event.quantity,
    };
    let cartId = this.storageService.cartId;
    this.globalService.addToCart(cartId, paramsObject, requestObject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success: any) => {
          this.apiCallSuccess = true;
          this.globalService.getCartsListByLegalEntity(undefined, false);
          this.requestObject = {
            currentPage: this.productsService.currentPage ? this.productsService.currentPage : 0,
            pageSize: this.productsService.rows ? this.productsService.rows : 30,
            query: ''
          };
          if (this.productsService.searchQuery) {
            this.requestObject.query += this.productsService.searchQuery;
          }
          if (this.productsService.filterQuery) {
            this.requestObject.query += this.productsService.filterQuery;
          }
          this.productsService.getProductsList(this.requestObject);
        },
        error: (error: any) => {
          this.apiCallSuccess = false;
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.storageService.query = '';
    this.productsService.searchQuery = '';
    this.productsService.filterQuery = '';
    this.globalService.clearMessagesOnDestroy();
  }
}
