<div *ngIf="productsList | async as prdctsList" class="mb-3">
	<div *ngIf="prdctsList?.products?.length > 0" class="col-12 prod-list-container">
		<div class="custom-pagination-position prod-pagination-top">
			<p-paginator 
				styleClass="cstm-pagination"
				[first]="first"
				[rows]="rows"
				[totalRecords]="totalRecords"
				(onPageChange)="onPageChange($event)">
			</p-paginator>
		</div>
		<div class="row m-0">
			<div *ngFor="let product of prdctsList?.products; let i = index;"
				class="col-md-6 col-xl-4 mb-3 px-2 align-items-stretch">
				<app-card-template
					[index]="i"
					[productId]="product?.baseProduct"
					[productName]="product?.name"
					[productPrice]="product?.discountPriceData?.value"
					[currency]="product?.discountPriceData?.currencyIso"
					[userLoggedIn]="!(userLoggedIn | async)"
					[minimumQty]="getUpdatedMinQty(product?.minQuantity, product?.interval)"
					[maximumQty]="product?.maxQuantity > 0 ? product?.maxQuantity : 1000"
					[productQuantity]="product?.minQuantity > 0 ? product?.minQuantity : product?.interval > 0 ? product?.interval : 1"
					[qtyStep]="product?.interval > 0 ? product?.interval : 1"
					[productNavigateUrl]="getProductUrl(product?.url)"
					[obsoleteFlag]="product?.obsoleteFlag"
					[quotationFlag]="product?.quotationFlag"
					[productImageUrl]="getImageUrl(product?.images)"
					[apiCallSuccess]="apiCallSuccess"
					(addToCartClk)="addToCart($event)">
				</app-card-template>
			</div>
		</div>
		<div class="d-flex justify-content-end mt-2">
			<p-paginator 
				styleClass="cstm-pagination"
				[first]="first"
				[rows]="rows"
				[totalRecords]="totalRecords"
				(onPageChange)="onPageChange($event)">
			</p-paginator>
		</div>
	</div>
	<ng-template #pagination>
		<app-pagination-template
			[currentPage]="prdctsList?.pagination?.currentPage"
			[rows]="prdctsList?.pagination?.pageSize"
			[totalPages]="prdctsList?.pagination?.totalResults"
			(pageChange)="onPageChange($event)">
		</app-pagination-template>
	</ng-template>
</div>
